import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";

const EditFeeReturn = ({ show, hide, studentDetails, reload }) => {
  const [loading, setloading] = useState(false);
  const [subAccountList, setsubAccountList] = useState([]);
  const [subAccountcontrList, setsubAccountcontrList] = useState([]);
  const [fee_return_amount, setfee_return_amount] = useState(
    studentDetails.fee_return_amount
  );
  const [remarks, setremarks] = useState(studentDetails.remarks);

  //   const [sub_account_id, setsub_account_id] = useState(
  //     studentDetails?.sub_account?.id
  //   );
  const [sub_account_contra_id, setsub_account_contra_id] = useState(
    studentDetails.sub_account_id
  );
  const [fee_sub_account_id, setfee_sub_account_id] = useState(
    studentDetails.fee_sub_account_id
  );

  console.log("student edit details==>", studentDetails);

  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  useEffect(() => {
    getSubAccountList();
    getSubContr();
  }, []);

  const getSubAccountList = () => {
    fetch(`${baseUrl}/show_fees_account_head`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("list", data);
        setsubAccountList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // contr list
  const getSubContr = () => {
    fetch(`${baseUrl}/subaccount_contra`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("list", data);
        setsubAccountcontrList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdatereturnFee = () => {
    const data = {
      fee_return_id: studentDetails.id,
      fee_sub_account_id: fee_sub_account_id
        ? fee_sub_account_id
        : studentDetails?.fee_sub_account_id,
      fee_return_amount: fee_return_amount
        ? fee_return_amount
        : studentDetails.fee_return_amount,
      sub_account_id: sub_account_contra_id
        ? sub_account_contra_id
        : studentDetails.sub_account_id,
      remarks: remarks ? remarks : studentDetails.remarks,
    };

    console.log(data);

    if (
      fee_return_amount == "" ||
      fee_sub_account_id == "" ||
      sub_account_contra_id == ""
    ) {
      alert.error("Please fill all fields");
    } else {
      setloading(true);
      fetch(`${baseUrl}/fee_return_edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            hide();
            alert.success(data.metadata.message);
            reload();
            setloading(false);
          } else {
            alert.error(data.metadata.message);
            setloading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  };

  return (
    <Modal onHide={hide} size="lg" show={show}>
      <Modal.Header>
        <Modal.Title>
          <h1>Fee return</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
            <label>Return Amount</label>
            <input
              defaultValue={studentDetails.fee_return_amount}
              //   value={fee_return_amount}
              type={"number"}
              className="form-control"
              onChange={(e) => setfee_return_amount(e.target.value)}
              placeholder="Return Amount"
            />
          </div>
          <div className="col-md-4">
            <label>Sub Account</label>
            <select
              defaultValue={studentDetails?.fee_sub_account_id}
              //   value={fee_sub_account_id}
              onChange={(e) => setfee_sub_account_id(e.target.value)}
              className="form-control"
            >
              <option>Select</option>
              {subAccountList.map((item) => (
                <option
                  value={item.id}
                  key={item.id}
                >{`${item.title}-${item.acode}`}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>Sub Account Contra</label>
            <select
              defaultValue={studentDetails.sub_account_id}
              //   value={sub_account_contra_id}
              onChange={(e) => setsub_account_contra_id(e.target.value)}
              className="form-control"
            >
              <option>Select</option>
              {subAccountcontrList.map((item) => (
                <option
                  value={item.id}
                  key={item.id}
                >{`${item.title}-${item.acode}`}</option>
              ))}
            </select>
          </div>
          <div className="col-md-12 mt-3">
            <label>Remarks</label>
            <input
              defaultValue={studentDetails.remarks}
              //   value={remarks}
              onChange={(e) => setremarks(e.target.value)}
              className="form-control"
              placeholder="Remarks"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <button onClick={UpdatereturnFee} className="btn btn-primary">
              Submit
            </button>
            <button onClick={hide} className="btn btn-danger">
              Close
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditFeeReturn;
